export const UPDATE_CLAIM = 'UPDATE_CLAIM';
export const REPLACE_CLAIM = 'REPLACE_CLAIM';

const claimReducer = (state = {}, action = { type: null, data: null }) => {
  switch (action.type) {
    case UPDATE_CLAIM:
      return { ...state, ...action.data };
    case REPLACE_CLAIM:
      return { ...action.data };
    default:
      return state;
  }
};

export default claimReducer;
