import '../../css/fonts.css';
import '../../css/style.css';

import dayjs from 'dayjs';
import { appWithTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React from 'react';
import { AuthContainer } from '../components/auth';
import Maintenance from '../components/structure/Maintenance';
import { MAINTENANCE_MODE } from '../config/config';
import { withRedux } from '../redux/withRedux';

const App = ({ Component, pageProps }) => {
  const { locale } = useRouter();
  if (MAINTENANCE_MODE) return <Maintenance />;

  if (locale !== 'en') {
    dayjs.locale(locale);
  }

  if (Component.needAuth)
    return (
      <React.Suspense fallback={<div>...</div>}>
        <AuthContainer>
          <React.StrictMode>
            <Component {...pageProps} />
          </React.StrictMode>
        </AuthContainer>
      </React.Suspense>
    );

  return (
    <React.Suspense>
      <Component {...pageProps} />
    </React.Suspense>
  );
};

// needed for  publicRuntime
export async function getServerSideProps() {
  return {
    props: {}, // will be passed to the page component as props
  };
}

export default withRedux(appWithTranslation(App), {
  ssr: false,
});
