import { combineReducers } from 'redux';
import claimReducer from './claimReducer';
import statusReducer from './statusReducer';

const rootReducer = combineReducers({
  claim: claimReducer,
  status: statusReducer,
});

export default rootReducer;
