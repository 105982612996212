import { createStore } from 'redux';
import rootReducer from './reducers/rootReducer';

export const initStore = (initialState) => {
  const store = createStore(rootReducer, initialState);

  return store;
};

export default initStore;
