import { createContext } from 'react';
import { UserHookReturn } from './types';

const UserContext = createContext<UserHookReturn>({
  user: null,
  isLoading: true,
  isRedirected: false,
  logout: null,
  mutateUser: null,
});

export default UserContext;
