export const STATUS_LOADING = 'STATUS_LOADING';
export const STATUS_RETOUR = 'STATUS_RETOUR';
export const STATUS_ERROR = 'STATUS_ERROR';

const statusReducer = (
  state = { sendDataStatus: 'idle' },
  action = { type: null, message: null }
) => {
  switch (action.type) {
    case STATUS_LOADING:
      return { sendDataStatus: 'loading' };
    case STATUS_RETOUR:
      return { sendDataStatus: 'idle' };
    case STATUS_ERROR:
      return { sendDataStatus: 'error', error: action.message };
    default:
      return state;
  }
};

export default statusReducer;
